var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h1',[_vm._v(_vm._s(_vm.$t('admin.analytics.title'))+" ")])]),(_vm.store)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('admin.analytics.description'))+": "+_vm._s(_vm.store.name)+" ")]):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"10"}},[(_vm.today)?_c('datepicker',{staticClass:"mt-5",attrs:{"outlined":"","label":"Select a date","min":"2021-05-01","max":this.$moment().format('YYYY-MM-DD'),"date":_vm.today},on:{"update:date":function($event){_vm.today=$event}}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card')],1),(_vm.analytics)?_vm._l((_vm.analytics.data),function(analysis,index){return _c('v-col',{key:analysis.table+index,attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h3',[_vm._v(_vm._s(analysis.title))])]),_c('v-card-text',[_c('p',[_vm._v(_vm._s(analysis.description))])]),_c('v-divider'),_c('v-card-text',[(!analysis.measure_unit)?[_c('simple-table',{attrs:{"table":{
                              daily: analysis.daily,
                              yesterday: analysis.yesterday,
                              weekly: analysis.weekly,
                              last_week: analysis.last_week,
                              monthly: analysis.monthly,
                              last_month: analysis.last_month,
                              annual: analysis.annual,
                              total: analysis.total,
                              updated_at: analysis.updated_at,
                          }}})]:_vm._e(),(analysis.measure_unit)?[_c('simple-table',{attrs:{"table":{
                              daily: analysis.daily+' '+analysis.measure_unit,
                              yesterday: analysis.yesterday+' '+analysis.measure_unit,
                              weekly: analysis.weekly+' '+analysis.measure_unit,
                              last_week: analysis.last_week+' '+analysis.measure_unit,
                              monthly: analysis.monthly+' '+analysis.measure_unit,
                              last_month: analysis.last_month+' '+analysis.measure_unit,
                              annual: analysis.annual+' '+analysis.measure_unit,
                              total: analysis.total+' '+analysis.measure_unit,
                              updated_at: analysis.updated_at,
                          }}})]:_vm._e()],2),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","block":"","to":{'name':'manager.analytics.single',params:{'id':analysis.slug}},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('commons.view'))+" "+_vm._s(_vm.$t('commons.chart'))+" ")])],1)],1)],1)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }