<template>
     <v-row>
          <v-col v-if="errors && message">
               <v-alert type="warning">
               {{message}}
               </v-alert>
          </v-col>
          <v-col cols="12">
          <v-row>
               <v-col cols="8">
                    <v-text-field
                         ref="searchUid"
                         v-on:keyup.enter="searchBy('uid')"
                         v-model="field.uid"
                         :label="$t('attributes.uid.label')"
                         :placeholder="$t('attributes.uid.placeholder')"
                         :hint="$t('attributes.uid.hint')"
                         :error-messages="errors.uid"
                         dense
                         outlined
                    ></v-text-field>
               </v-col>
               <v-col cols="4">
                    <v-btn block outlined :loading="loading" color="primary" @click="searchBy('uid')">
                         <v-icon class="mx-3">mdi-magnify</v-icon>
                         search
                    </v-btn>
               </v-col>
          </v-row>
          </v-col>
          <v-col cols="12">
          <v-row>
               <v-col cols="8">
                    <v-text-field
                     v-on:keyup.enter="searchBy('email')"
                         v-model="field.email"
                         :label="$t('attributes.email.label')"
                         :placeholder="$t('attributes.email.placeholder')"
                         :hint="$t('attributes.email.hint')"
                          :error-messages="errors.email"
                         dense
                         outlined
                    ></v-text-field>
               </v-col>
               <v-col cols="4">
                    <v-btn block outlined :loading="loading" color="primary" @click="searchBy('email')">
                         <v-icon class="mx-3">mdi-magnify</v-icon>
                         search
                    </v-btn>
               </v-col>
          </v-row>
          </v-col>
          <v-col cols="12">
          <v-row>
               <v-col cols="8">
                    <v-row>
                         <v-col cols="4">
                              <v-select
                                   v-if="prefixes"
                                   v-model="field.prefix"
                                   :items="prefixes"
                                   :label="$t('attributes.prefixes.label')"
                                   :item-text="textPrefixItem"
                                   item-value="prefix"
                                   return-object
                                   :error-messages="errors.phone"
                                   @change="changePrefix"
                                   dense
                                   outlined
                              ></v-select>
                         </v-col>
                         <v-col cols="8">
                              <v-text-field
                                   v-on:keyup.enter="searchBy('phone')"
                                   v-model="field.phone_number"
                                   :label="$t('attributes.phone.label')"
                                   :placeholder="$t('attributes.phone.placeholder')"
                                   :hint="$t('attributes.phone.hint')"
                                   dense
                                   :error-messages="errors.phone"
                                   outlined
                              ></v-text-field>
                         </v-col>
                    </v-row>
               </v-col>
               <v-col cols="4">
                    <v-btn block outlined :loading="loading" color="primary" @click="searchBy('phone')">
                         <v-icon class="mx-3">mdi-magnify</v-icon>
                         search
                    </v-btn>
               </v-col>
          </v-row>
          </v-col>
          <v-col cols="12">
          <v-row>
               <v-col cols="8">
                    <v-row>
                         <v-col>
                              <v-text-field
                                   v-on:keyup.enter="searchBy('name')"
                                   v-model="field.name.firstname"
                                   :label="$t('attributes.firstname.label')"
                                   :placeholder="$t('attributes.firstname.placeholder')"
                                   :hint="$t('attributes.firstname.hint')"
                                   :error-messages="errors['name.firstname']"
                                   dense
                                   outlined
                              ></v-text-field>
                         </v-col>
                         <v-col>
                              <v-text-field
                                   v-on:keyup.enter="searchBy('name')"
                                   v-model="field.name.lastname"
                                   :label="$t('attributes.lastname.label')"
                                   :placeholder="$t('attributes.lastname.placeholder')"
                                   :hint="$t('attributes.lastname.hint')"
                                   :error-messages="errors['name.lastname']"
                                   dense
                                   outlined
                              ></v-text-field>
                         </v-col>
                    </v-row>
               </v-col>
               <v-col cols="4">
                    <v-btn block outlined :loading="loading" color="primary" @click="searchBy('name')">
                         <v-icon class="mx-3">mdi-magnify</v-icon>
                         search
                    </v-btn>
               </v-col>
          </v-row>
          </v-col>
          <v-col cols="12" v-if="dialog">
          <v-card
               class="mx-auto"
               tile
               >
               <v-list v-if="users">
                    <v-list-item-group
                    color="primary"
                    >
                    <v-list-item
                         v-for="(item, i) in users"
                         :key="i"
                         :to="{name:'manager.users.single',params:{'id':item.uid}}"
                    >
                         <v-list-item-icon>
                         <v-icon>mdi-account</v-icon>
                         </v-list-item-icon>
                         <v-list-item-content>
                         <v-list-item-title v-text="item.email"></v-list-item-title>
                         {{item.fullname}}
                         </v-list-item-content>
                              <v-btn color="primary" icon :to="{name:'manager.users.single',params:{'id':item.uid}}">
                                   <v-icon>mdi-eye</v-icon>
                              </v-btn>
                    </v-list-item>
                    </v-list-item-group>
               </v-list>
          </v-card>
          </v-col>
     </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     name: "SearchUserForm",
     computed: {
     ...mapGetters({
          prefixes: 'general/prefixes'
      }),
     },
     data: () => ({
          by: null,
          message: false,
          loading: false,
          dialog: false,
          users: null,
          errors: {},
          field:{
               email: null,
               phone: null,
               prefix: null,
               phone_number: null,
               uid: null,
               name: {
                    firstname: null,
                    lastname: null,
               }
          }
     }),
     mounted(){
          this.$refs.searchUid.focus();
          if(!this.prefixes){
               this.loading = true
               console.log('No Prefixes Found')
               this.$store.dispatch('general/getPrefixesApi')
               this.loading = false
          }
     },
     methods:{
          searchBy(field){
               this.field.phone = this.field.prefix+this.field.phone_number;
               this.loading = true
               this.errors = {},
               this.message = null,
               this.by = field
               var filter = [];
               filter[this.by] = this.field[this.by]
               console.log(filter)
               this.$store.dispatch('managerUsers/searchUsers',filter)
               .then((response) => {
                    console.log('Get User!',response.data.data)
                    this.dialog = true
                    this.loading = false
                    this.users = response.data.data;

                    if(response.data.data.length == 1){
                         console.log("IS SINGLE");
                         var user = response.data.data[0]
                         var newroute = {name:'manager.users.single',params:{id:user.uid}};
                         this.$router.push(newroute);
                    }

               })
               .catch((err) => {
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false
               }) 
          },
          changePrefix(item){
               this.field.prefix = item.prefix
               this.field.phone = this.field.prefix+this.field.phone_number;
          },
          textPrefixItem: item => item.country + ' (' + item.prefix + ')',
     }
}
</script>
