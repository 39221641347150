<template>
     <v-row>
           <v-col cols="12" >
                <v-card :loading="loading">
                     <v-card-title>
                           <h2> {{$t('commons.search')}} {{$t('commons.user')}} {{ $t('commons.activate')}}</h2>
                     </v-card-title>
                     <v-divider></v-divider>
                     <v-card-text>
                          <v-row>
                              <v-col cols="8">
                                   <v-text-field
                                        ref="searchUid"
                                        v-on:keyup.enter="searchBy('uid')"
                                        v-model="fields.uid"
                                        :label="$t('attributes.uid.label')"
                                        :placeholder="$t('attributes.uid.placeholder')"
                                        :hint="$t('attributes.uid.hint')"
                                        :error-messages="errors.uid"
                                        dense
                                        outlined
                                   ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                   <v-btn block outlined :loading="loading" color="primary" @click="searchBy('uid')">
                                        <v-icon class="mx-3">mdi-magnify</v-icon>
                                        search
                                   </v-btn>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="8">
                                   <v-text-field
                                        ref="searchEmail"
                                        v-on:keyup.enter="searchBy('email')"
                                        v-model="fields.email"
                                        :label="$t('attributes.email.label')"
                                        :placeholder="$t('attributes.email.placeholder')"
                                        :hint="$t('attributes.email.hint')"
                                        :error-messages="errors.email"
                                        dense
                                        outlined
                                   ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                   <v-btn block outlined :loading="loading" color="primary" @click="searchBy('email')">
                                        <v-icon class="mx-3">mdi-magnify</v-icon>
                                        search
                                   </v-btn>
                              </v-col>
                          </v-row>
                          <v-row>
                               <v-col cols="8">
                                   <v-row>
                                        <v-col cols="4">
                                             <v-select
                                                  v-if="prefixes"
                                                  v-model="fields.prefix"
                                                  :items="prefixes"
                                                  :label="$t('attributes.prefixes.label')"
                                                  :item-text="textPrefixItem"
                                                  item-value="prefix"
                                                  return-object
                                                  :error-messages="errors.phone"
                                                  @change="changePrefix"
                                                  dense
                                                  outlined
                                             ></v-select>
                                        </v-col>
                                        <v-col cols="8">
                                             <v-text-field
                                                  v-on:keyup.enter="searchBy('phone')"
                                                  v-model="fields.phone_number"
                                                  :label="$t('attributes.phone.label')"
                                                  :placeholder="$t('attributes.phone.placeholder')"
                                                  :hint="$t('attributes.phone.hint')"
                                                  dense
                                                  @change="changePhone"
                                                  :error-messages="errors.phone"
                                                  outlined
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>
                              </v-col>
                              <v-col cols="4">
                                   <v-btn block outlined :loading="loading" color="primary" @click="searchBy('phone')">
                                        <v-icon class="mx-3">mdi-magnify</v-icon>
                                        search
                                   </v-btn>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="8">
                                   <v-row>
                                        <v-col>
                                             <v-text-field
                                                  v-on:keyup.enter="searchBy('name')"
                                                  v-model="fields.name.firstname"
                                                  :label="$t('attributes.firstname.label')"
                                                  :placeholder="$t('attributes.firstname.placeholder')"
                                                  :hint="$t('attributes.firstname.hint')"
                                                  :error-messages="errors['name.firstname']"
                                                  dense
                                                  outlined
                                             ></v-text-field>
                                        </v-col>
                                        <v-col>
                                             <v-text-field
                                                  v-on:keyup.enter="searchBy('name')"
                                                  v-model="fields.name.lastname"
                                                  :label="$t('attributes.lastname.label')"
                                                  :placeholder="$t('attributes.lastname.placeholder')"
                                                  :hint="$t('attributes.lastname.hint')"
                                                  :error-messages="errors['name.lastname']"
                                                  dense
                                                  outlined
                                             ></v-text-field>
                                        </v-col>
                                   </v-row>
                              </v-col>
                              <v-col cols="4">
                                   <v-btn block outlined :loading="loading" color="primary" @click="searchBy('name')">
                                        <v-icon class="mx-3">mdi-magnify</v-icon>
                                        search
                                   </v-btn>
                              </v-col>
                         </v-row>
                     </v-card-text>
                     <v-divider></v-divider>
                     <v-card-text v-if="message">
                         <v-alert type="error" v-if="Object.keys(errors).length">
                              {{message}}<br>
                              <ul>
                                   <li v-for="(values,error) in errors" :key="error">
                                        <b>{{error}}: </b> <span v-for="(val,n) in values" :key="n">{{val}}</span>
                                   </li>
                              </ul>
                         </v-alert>
                         <v-alert  v-else type="success">
                              {{message}}
                              <span v-if="activated">
                                   <br>
                                   <v-btn :to="{name:'manager.users.single',params:{id:activated}}" color="success">GO TO USER PAGE</v-btn>
                              </span>
                         </v-alert>
                     </v-card-text>
                     <v-divider></v-divider>
                     <v-card-text v-if="users.length >= 1">
                         <h3>{{$t('commons.choose')}} {{$t('commons.user')}}</h3>
                         <v-list v-if="users">
                              <v-list-item-group
                              color="primary"
                              >
                              <v-list-item
                                   v-for="(user, i) in users"
                                   :key="i"
                                   @click="setUser(user)"
                              >
                                   <v-list-item-icon>
                                        <v-icon>mdi-account</v-icon>
                                   </v-list-item-icon>
                                   <v-list-item-content>
                                        <v-list-item-title v-text="user.email"></v-list-item-title>
                                        <v-list-item-title v-text="user.phone"></v-list-item-title>
                                        {{user.title_name}}
                                        {{user.firstname}}
                                        {{user.lastname}}
                                       ( {{user.date_of_birth}} )
                                   </v-list-item-content>
                              </v-list-item>
                              </v-list-item-group>
                         </v-list>
                     </v-card-text>
                </v-card>
           </v-col>
           <v-col cols="12" id="formtocreate">
               <v-card  v-if="user">
                    <v-card-text>
                    <v-row>
                         <v-col cols="12">
                              <v-text-field
                                   v-model="user.uid"
                                   :label="$t('attributes.uid.label')"
                                   :placeholder="$t('attributes.uid.placeholder')"
                                   :hint="$t('attributes.uid.hint')"
                                   :error-messages="errors.uid"
                                   dense
                                   outlined
                                   readonly
                              ></v-text-field>
                         </v-col>
                         <v-col cols="12">
                             <v-row>
                                  <v-col cols="4">
                                       <v-select
                                             v-model="user.title_name"
                                             v-if="titles"
                                             :items="titles"
                                             :label="$t('attributes.title_name.label')"
                                             item-text="title"
                                             item-value="title"
                                             return-object
                                             :error-messages="errors.title_name"
                                             @focus="errors.title_name = null"
                                             @change="changeTitle"
                                             dense
                                             outlined
                                        ></v-select>
                                  </v-col>
                                   <v-col cols="4">
                                        <v-text-field
                                             v-model="user.firstname"
                                             :label="$t('attributes.firstname.label')"
                                             :placeholder="$t('attributes.firstname.placeholder')"
                                             :hint="$t('attributes.firstname.hint')"
                                             :error-messages="errors['name.firstname']"
                                             dense
                                             outlined
                                        ></v-text-field>
                                   </v-col>
                                   <v-col cols="4">
                                        <v-text-field
                                             v-model="user.lastname"
                                             :label="$t('attributes.lastname.label')"
                                             :placeholder="$t('attributes.lastname.placeholder')"
                                             :hint="$t('attributes.lastname.hint')"
                                             :error-messages="errors['name.lastname']"
                                             dense
                                             outlined
                                        ></v-text-field>
                                   </v-col>
                             </v-row>
                         </v-col>
                         <v-col cols="12">
                              <v-text-field
                                   v-model="user.email"
                                   :label="$t('attributes.email.label')"
                                   :placeholder="$t('attributes.email.placeholder')"
                                   :hint="$t('attributes.email.hint')"
                                   :error-messages="errors.email"
                                   dense
                                   outlined
                              ></v-text-field>
                         </v-col>
                         <v-col cols="12">
                              <v-text-field
                                   v-model="user.phone"
                                   :label="$t('attributes.phone.label')"
                                   :placeholder="$t('attributes.phone.placeholder')"
                                   :hint="$t('attributes.phone.hint')"
                                   :error-messages="errors.phone"
                                   dense
                                   outlined
                              ></v-text-field>
                         </v-col>
                         <v-col cols="12" >
                         <datepicker 
                                   :label="$t('attributes.date_of_birth.label')"
                                   :placeholder="$t('attributes.date_of_birth.placeholder')"
                                   :date.sync="user.date_of_birth"
                                   :hint="'min 18'"
                                   :min="mindate"
                                   :max="maxdate"
                                   :errors="errors.date_of_birth"
                                    dense
                                             outlined
                         ></datepicker>
                         </v-col>
                         <v-col cols="7">
                              <v-text-field
                                             type="text"
                                             v-model="user.city"
                                             :label="$t('attributes.city.label')"
                                             :placeholder="$t('attributes.city.placeholder')"
                                             :hint="$t('attributes.city.hint')"
                                             :error-messages="errors.city"
                                             @focus="errors.city = null"
                                             dense
                                             outlined
                              ></v-text-field>
                         </v-col>
                         <v-col cols="5">
                                   <v-text-field
                                             type="text"
                                             v-model="user.zip_code"
                                             :label="$t('attributes.zip_code.label')"
                                             :placeholder="$t('attributes.zip_code.placeholder')"
                                             :hint="$t('attributes.zip_code.hint')"
                                             :error-messages="errors.zip_code"
                                             @focus="errors.zip_code = null"
                                             dense
                                             outlined
                                   ></v-text-field>
                         </v-col>
                         <v-col cols="12">
                         <label>
                         <div style="white-space: pre-line">
                              <b>{{ $t('privacy.privacy.label')}}</b>
                              <br> {{ $t('privacy.privacy.text')}}
                         </div>
                         </label>
                         <v-checkbox
                                   v-model="user.general"
                                   value
                                   :error-messages="errors.general"
                                   @click="errors.general = null"
                         >
                         <template v-slot:label>
                              <div>
                                   <b>{{ $t('privacy.general.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.general.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.general.link')">{{ $t('privacy.general.text_link')}}</a>
                                   </small>
                              </div>
                         </template>
                         </v-checkbox>
                         <v-checkbox
                                   v-model="user.marketing"
                                   @click="errors.marketing = null"
                                   value
                                   :error-messages="errors.marketing"
                         >
                          <template v-slot:label>
                              <div>
                                   <b>{{ $t('privacy.marketing.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.marketing.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.marketing.link')">{{ $t('privacy.marketing.text_link')}}</a>
                                   </small>
                              </div>
                          </template>
                         </v-checkbox>
                         <!-- NO SURVEY <v-checkbox 
                                   v-model="user.survey"
                                   @click="errors.survey = null"
                                   value
                                   :error-messages="errors.survey"
                         >
                          <template v-slot:label>
                               <div>
                                   <b>{{ $t('privacy.survey.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.survey.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.survey.link')">{{ $t('privacy.survey.text_link')}}</a>
                                   </small>
                              </div>
                          </template>
                         </v-checkbox> -->
                         <v-checkbox
                                   v-model="user.third_party"
                                   @click="errors.third_party = null"
                                   value
                                   :error-messages="errors.third_party"
                         >
                          <template v-slot:label>
                               <div>
                                   <b>{{ $t('privacy.third_party.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.third_party.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.third_party.link')">{{ $t('privacy.third_party.text_link')}}</a>
                                   </small>
                              </div>
                          </template>
                         </v-checkbox>
                         <div style="white-space: pre-line">
                             <small>{{ $t('privacy.privacy.finaltext') }} 
                                  <a target="_blank" @click.stop :href="$t('privacy.privacy.link')">{{ $t('privacy.privacy.text_link')}}</a></small>
                         </div>
                         </v-col>
                    </v-row>
                    </v-card-text>
                    <v-card-actions>
                         <v-btn color="primary" :loading="loading" block @click="activateUser">{{$t('commons.activate')}}</v-btn>
                    </v-card-actions>
               </v-card>
               <!-- <json-debug :objs="[{user}]"></json-debug> -->
           </v-col>
     </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import datepicker from '@/components/Commons/FormValues/datepicker.vue';

export default {
     name:"ActivateCardForm",
     components:{
          datepicker
     },
     props:{
          store:{
               type: Object,
               required: true,
          }
     },
     computed: {
          ...mapGetters({
               loading: 'loading/loading',
               titles: 'general/titles',
               prefixes: 'general/prefixes',
               errors: 'managerSleeperUsers/errors',
               message: 'managerSleeperUsers/message',
               users: 'managerSleeperUsers/users',
               user: 'managerSleeperUsers/user',
               activated: 'managerSleeperUsers/activated',
          }),
     },
     data: () => ({
          maxdate: null,
          mindate: null,
          fields: {
               uid: null,
               email: null,
               phone: null,
               name: {
                    firstname: null,
                    lastname: null,
               },
               store_uid: null
          },
     }),
     mounted(){
         console.log('USER GOING TO CREATE IN STORE', this.store)
          if(!this.titles){
               console.log('No Titles Found')
               this.$store.dispatch('general/getTitles', this.$i18n.locale)
          }
          if(!this.prefixes){
               console.log('No Prefixes Found')
               this.$store.dispatch('general/getPrefixesApi')
          }
          this.maxdate = this.$moment().subtract('18','years').format('YYYY-MM-DD')
          this.mindate = this.$moment().subtract('99','years').format('YYYY-MM-DD')
     },
     methods:{
          async searchBy(field){
              console.log(field,this.fields[field]);
               var filter = [];
               filter[field] = this.fields[field]
               console.log(filter)
               this.$store.dispatch('managerSleeperUsers/searchUsers',filter)
               .then((response)=>{
                    if(response.data.data.length == 1){
                         this.setUser(response.data.data[0])
                    }
               })
          },
          async activateUser(){
               delete this.user.id;
               delete this.user.created_at;
               delete this.user.updated_at;
               this.user.store_uid = this.store.store_uid;
               this.user.country = this.store.country;
               console.log(this.user);
               this.$store.dispatch('managerSleeperUsers/activateCard',this.user)
          },
          setUser(user){
               this.$store.dispatch('managerSleeperUsers/setUser',user).then(()=>{
                    document.getElementById('formtocreate').scrollIntoView({
                         behavior: 'smooth'
                    });
               })
               this.changeTitle({title: user.title_name,gender : user.gender })
          },
          changePrefix(item){
               this.fields.prefix = item.prefix
               this.fields.phone = this.fields.prefix+this.fields.phone_number;
          },
          changePhone(){
               this.fields.phone = this.fields.prefix + this.fields.phone_number;
          },
          textPrefixItem: item => item.country + ' (' + item.prefix + ')',
          changeTitle(item){
               console.log(item)
               this.user.gender = item.gender;
               this.user.title_name = item.title;
          },
     }
}
</script>