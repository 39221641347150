<template>
     <v-container>
          <v-container>
          <v-row>
               <v-col cols="12">
                    <v-card :loading="loading">
                         <template v-if="store">
                         <v-card-title primary-title >
                              <v-icon class="me-3">mdi-store</v-icon>
                              <h3>{{store.name}}</h3>
                              <v-spacer></v-spacer>
                              <div v-if="stores.length > 1">
                                   <change-store > </change-store>
                              </div>
                         </v-card-title>
                         </template>
                         <template v-else>
                              <v-card-title primary-title >
                                   LOADING
                              </v-card-title>
                         </template>
                    </v-card>
               </v-col>
          </v-row>
          </v-container>
          <router-view></router-view>
     </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import ChangeStore from '@/components/Manager/Stores/ChangeStore';


export default {
     components:{
          'change-store': ChangeStore,
     },
      computed: {
     ...mapGetters({
          store: 'managerStores/store',
          stores: 'managerStores/stores',
      }),
     },
     data: () =>({
        errors:{},
        loading: false,
     }),
     beforeMount() {
          if(!this.store){          
              this.getStores()
          }
     },
     methods:{
          getStores(){
               this.loading = true;
               console.log('getStores')
               this.$store.dispatch('managerStores/getStores')
              .then((response) => {
                    console.log('getStores',response)
                    this.loading = false
               })
               .catch((err) => {
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false
               }) 
          }
     }
}
</script>