<template>
     <div>
             <v-container class="my-5" >
          <v-row>
               <v-col cols="12" v-if="message">
                    <v-alert type="error">
                         {{message}}
                         <ul  v-if="Object.keys(errors).length">
                              <li v-for="(err,field) in errors" :key="field">
                                   <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                              </li>  
                         </ul>
                    </v-alert>
               </v-col>
               <v-col cols="12"  v-if="user">
                    <view-profile :loading="loading" :key="user.uid" :user="user"></view-profile>
               </v-col>
               <v-col cols="12" >
                   <v-card :loading="loading">
                        <v-card-actions v-if="user">
                         <v-row class="text-center">
                             <v-col class="my-2" v-if="user.privacy.user_confirm">
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.profile')" :title="$t('commons.profile')">
                                  <slot>
                                       <form-user-profile :uid="user.uid" :key="user.uid" :profile="user.profile"></form-user-profile>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2" v-if="user.privacy.user_confirm">
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.privacy')" :title="$t('commons.privacy')" >
                                  <slot>
                                       <form-user-privacy :uid="user.uid" :privacy="user.privacy"></form-user-privacy>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2" >
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.email')" :title="$t('commons.email')" >
                                  <slot>
                                       <form-user-settings :uid="user.uid" :email="user.email"></form-user-settings>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2">
                             <dialog-simple width="900px" :button="$t('commons.edit')+' '+$t('commons.phone')" :title="$t('commons.phone')" >
                                  <slot>
                                      <form-user-settings :uid="user.uid" :phone="user.phone"></form-user-settings>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                             <v-col class="my-2">
                             <dialog-simple width="900px" :button="$t('commons.add')+' '+$t('commons.receipt')" :title="$t('commons.receipt')" >
                                  <slot>
                                       <create-receipts  v-if="store"  :key="user.uid" :store_uid="store.store_uid" :user_uid="user.uid" ></create-receipts>
                                  </slot>
                             </dialog-simple>
                             </v-col>
                         </v-row>
                        </v-card-actions>
                   </v-card>
               </v-col>
               <v-col cols="12" >
                   <v-card :loading="loading" v-if="user">
                        <v-card-text>
                             <v-tabs
                                   v-model="tab"
                                   centered
                                   grow
                                   color="primary"
                              >
                                   <v-tab>
                                   {{ $t('commons.coupons') }}
                                   </v-tab>
                                   
                             </v-tabs>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                             <v-tabs-items v-model="tab">
                                  <v-tab-item
                                   transition="fade"
                                   reverse-transition="fade"
                                   >
                                        <template v-if="user.privacy.user_confirm">
                                             <coupons-list  :uid="user.uid" :key="user.uid"></coupons-list>
                                        </template>
                                        <template v-else>
                                             <h3>{{$t('user.hidden-data')}}</h3>
                                        </template>
                                   </v-tab-item>
                              
                             </v-tabs-items>
                        </v-card-text>  
                   </v-card>
               </v-col>
          </v-row>
          </v-container>
     </div>
</template>


<script>
import {mapGetters} from "vuex";
import ViewProfile from '@/components/User/Profile/ViewProfile.vue';
import DialogSimple from '@/components/Commons/DialogSimple.vue';
import FormUserProfile from '@/components/User/Profile/FormUserProfile.vue';
import FormUserPrivacy from '@/components/User/Profile/FormUserPrivacy.vue';
import FormUserSettings from '@/components/User/Profile/FormUserSettings.vue';
import CouponsList from '@/components/User/Coupons/CouponsList.vue';
import CreateReceipts from '@/components/Manager/Receipts/CreateReceipts'

export default {
     components: { 
          ViewProfile,
          DialogSimple,
          FormUserProfile,
          FormUserPrivacy,
          FormUserSettings,
          CouponsList, 
          'create-receipts':CreateReceipts,
     },
     computed: {
          ...mapGetters({
                store: 'managerStores/store',
                user: 'managerUsers/user',
                isAdmin: 'auth/isAdmin',
          }),
     },
     beforeMount() {
          this.loading = true;
          if(!this.user || this.user.uid != this.$route.params.id){
               this.getUser(this.$route.params.id)
          }else{
               this.loading = false;
          }
     },
     data: () => ({
          loading: true,
          message: null,
          tab: null,
          errors: {}
     }),
     created() {
     this.$watch(
          () => this.$route.params,
               (newid, oldid) => {
                   if(newid != oldid){
                        this.loading = true;
                         this.getUser(newid.id)
                   }
               }
          )
     },
     methods:{
          getUser(uid){
              this.loading = true
              this.$store.dispatch('managerUsers/getUser',uid)
               .then((response) => {
                    console.log('SUCCESS',response)
                    this.loading = false;
               })
               .catch((err) => {
                    console.log('err',err)
                    this.loading = false
                    this.message = err.response.data.message
                    this.errors = (err.response.data.errors) ? err.response.data.errors : {}
               })
          },
     }
}
</script>