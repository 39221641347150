<template>
     <div>
          <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   NO ACTION
               </v-col>
          </v-row>
          </v-container>
     </div>
</template>


<script>

export default {
     components: {  },
     computed: {
          
     },
     beforeMount() {
         
     },
     data: () => ({
          loading: true,
     }),
     methods:{
          
     }
}
</script>