<template>
     <v-row>
          <v-col cols="12" class="my-3">
               <h2>{{$t('commons.restore')+' '+$t('commons.user')}}</h2>
          </v-col>
           <v-col cols="12" v-if="!message">

                <v-row>
                    <v-col cols="12">
                         <v-text-field
                              v-model="form.uid"
                              :label="$t('attributes.uid.label')"
                              :placeholder="$t('attributes.uid.placeholder')"
                              :hint="$t('attributes.uid.hint')"
                              :error-messages="errors.uid"
                              @focus="errors.uid = null"
                              outlined
                         ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                         <v-text-field
                              v-model="form.email"
                              :label="$t('attributes.email.label')"
                              :placeholder="$t('attributes.email.placeholder')"
                              :hint="$t('attributes.email.hint')"
                              :error-messages="errors.email"
                              @focus="errors.email = null"
                              outlined
                         ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                         <v-select
                              v-if="prefixes"
                              v-model="form.prefix"
                              :items="prefixes"
                              :label="$t('attributes.prefixes.label')"
                              :item-text="textPrefixItem"
                              item-value="prefix"
                              return-object
                              :error-messages="errors.phone"
                              @change="changePrefix"
                              outlined
                         ></v-select>
                    </v-col>
                    <v-col cols="8">
                         <v-text-field
                                   type="number"
                                   v-model="form.phone_number"
                                   :label="$t('attributes.phone.label')"
                                   :placeholder="$t('attributes.phone.placeholder')"
                                   :hint="$t('attributes.phone.hint')"
                                   :error-messages="errors.phone"
                                   @focus="errors.phone = null"
                                   @change="changePhone"
                                   outlined
                         ></v-text-field>
                    </v-col>
                </v-row>

               
                <v-btn @click="restoreUser" color="primary" block :loading="loading">{{$t('commons.restore')}}</v-btn>
           </v-col>
           <v-col cols="12" v-if="message">
               <v-alert type="success">
                              {{message}}
                              <br>
                              <br>
                              <v-btn :to="{name:'manager.users.single',params:{id:form.uid}}" color="success">GO TO USER PAGE</v-btn>
               </v-alert>
           </v-col>
     </v-row>
</template>

<script>
import {mapGetters} from "vuex";
export default {
     name:"RestoreUserForm",
     props:{
          store_uid:{
               type: String,
               required: true,
          }
     },
     computed: {
     ...mapGetters({
          titles: 'general/titles',
          prefixes: 'general/prefixes'
      }),
     },
     data: () => ({
          loading: false,
          errors: {},
          message: null,
          form: {
               store_uid: null,
               uid: null,
               phone: null,
               email: null,
               prefix: null,
               phone_number: null,
          },
     }),
     mounted(){
          this.form.store_uid = this.store_uid
     },
     beforeMount(){
          if(!this.prefixes){
               console.log('No Prefixes Found')
               this.$store.dispatch('general/getPrefixesApi')
          }
     },
     methods:{
          async restoreUser(){
               this.loading = true
               console.log(this.form)
               this.$store.dispatch('managerUsers/restoreUser',this.form)
               .then((response) => {
                      
                    this.message = response.data.message
                    console.log('restoreUser success!',response)
                    this.loading = false;
                    
               })
               .catch((err) => {
                    console.error('CREATE ERROR!',err)
                    //this.message = err.response.data.message
                    this.errors = (err.response.data.errors) ? err.response.data.errors : {'uid':"invalid"},
                    this.loading = false;
               }) 
          },
          changePrefix(item){
               this.form.prefix = item.prefix
               this.form.phone = this.form.prefix+this.form.phone_number;
          },
          changePhone(){
               this.form.phone = this.form.prefix + this.form.phone_number;
          },
          textPrefixItem: item => item.country + ' (' + item.prefix + ')',
     }
}
</script>