<template>
     <v-card :loading="loading">
          <v-overlay
           absolute="absolute"
           v-if="checkAviability()">

               <v-card>
                   <v-card-text>
                    <v-icon>mdi-lock</v-icon>
                    BLOCKED
                   </v-card-text>
               </v-card>

          </v-overlay>
          <v-card-title primary-title>
               <h3>{{$t('commons.create')}} {{$t('commons.receipt')}}</h3>
          </v-card-title>
          <v-card-text>
          <v-row>
               
                                   <v-col v-if="Object.keys(errors).length">
                                        <v-alert
                                             border="top"
                                             colored-border
                                             type="error"
                                             elevation="2"
                                        >
                                             <ul>
                                             <li v-for="(err,field) in errors" :key="field">
                                                  <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                                             </li>  
                                             </ul>
                                        </v-alert>
                                   </v-col>
                                   <v-col cols="12">
                                        <v-text-field
                                             :disabled="(this.user_uid) ? true : false"
                                             v-model="form.user_uid"
                                             :label="$t('attributes.uid.label')"
                                             :placeholder="$t('attributes.uid.placeholder')"
                                             :hint="$t('attributes.uid.hint')"
                                             :error-messages="errors.user_uid"
                                             outlined
                                             
                                        ></v-text-field>
                                   </v-col>
                                   <v-col cols="5">
                                        <v-text-field
                                             type="text"
                                             v-model="form.number"
                                             :label="$t('attributes.number.label')"
                                             :placeholder="$t('attributes.number.placeholder')"
                                             :hint="$t('attributes.number.hint')"
                                             :error-messages="errors.number"
                                             outlined
                                        ></v-text-field>

                                   </v-col>
                                   <v-col cols="7">
                                        <v-text-field
                                             type="number"
                                             v-model="form.amount"
                                             :label="$t('attributes.amount.label')"
                                             :placeholder="$t('attributes.amount.placeholder')"
                                             :hint="$t('attributes.amount.hint')"
                                             :error-messages="errors.amount"
                                             :prepend-icon="(form.amount>=0) ? 'mdi-plus' : 'mdi-minus'"
                                             @click:prepend="form.amount = form.amount*-1"
                                             outlined
                                             prefix="€"
                                        ></v-text-field>
                                   
                                   </v-col>
                                   <v-col cols="12">
                                        <v-btn block :disabled="loading" :loading="loading" @click="createReceipt" color="primary">CREATE</v-btn>
                                   </v-col>
                                   <v-col  v-if="message">
                                        <v-alert
                                            dismissible
                                             border="top"
                                             colored-border
                                             :type="(Object.keys(errors).length) ? 'error':'success'"
                                             elevation="2"
                                        >
                                        {{message}}
                                   </v-alert>
                                   </v-col>
                              
          </v-row>
          </v-card-text>
     </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
     name:"CreateReceipts",
     components:{
         
     },
     computed: {
     ...mapGetters({
          usercountry: 'auth/country'
      }),
     },
     props:{
          user_uid:{
               type: String,
               default: null,
          },
          store_uid:{
               type: String,
               required: true,
          },
     },
     data: () => ({
          loading: null,
          store_disabled: true,
          is_neg: false,
          form:{
               amount: 0.00,
               currency: 'EUR',
               number: 1,
          },
          errors:{},
          message:null
     }),
     beforeMount(){
          console.log('this.user_uid',this.user_uid)
          this.form.user_uid = this.user_uid;
          this.form.store_uid = this.store_uid;
     },
     methods:{
          async createReceipt(){
               this.loading = true
               this.errors = {}
               console.log(this.form)

               var go = true
               if(this.form.amount >= 1000 || this.form.amount <= -1000 ){
                    go = confirm('AMMOUNT = '+this.form.amount+'€ \nIS IT CORRECT? \npress OK to continue,');
               }

               if(go){
                   
               this.$store.dispatch('managerReceipts/createReceipt', this.form)
               .then((response) => {
                    this.message = response.data.message

                    this.form = { 
                         amount: 0.00,
                         user_uid: this.user_uid,
                         currency: 'EUR',
                         store_uid: this.store_uid,
                         number: (this.form.number) ?  this.form.number+1 : 1,
                    }

                    if(this.user_uid){
                         location.reload();
                    }

                    this.loading = false
               })
               .catch((err) => {
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false
               })
               }else{
                    this.form.amount = 0;
                    this.loading = false
                    this.errors = {
                         amount: 'ERROR'
                    }
               }

          },
          checkAviability(){
               var  today = this.$moment();
               var lastday = this.$moment("2024-01-01");
               var  invalid_country = ['PRT','ES'];

               console.log("DATES!!!", [today , lastday])
               console.log("IS >=", today >= lastday)

               if(invalid_country.includes(this.usercountry) && today >= lastday){
                    return true
               }

               return false;
          }
     }
}
</script>