<template>
     <v-container>
          <v-row>
               <v-col cols="12">
                   <v-card>
                        <v-card-title primary-title>
                             <h1>{{$t('manager.receipts.title')}}</h1>
                        </v-card-title>
                        <v-card-text>
                              <h3>{{$t('manager.receipts.description')}}</h3>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12">
                    <create-receipts  v-if="store" :key="store.store_uid" :store_uid="store.store_uid" ></create-receipts>
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import CreateReceipts from '@/components/Manager/Receipts/CreateReceipts'
import {mapGetters} from "vuex";

export default {
     computed: {
          ...mapGetters({
                store: 'managerStores/store',
          }),
     },
     components:{
          'create-receipts':CreateReceipts,
     },
     data: () => ({
          loading: false,
          errors: null,
          message: null,
     }),
     beforeMount() {
        
     },
     methods:{
         
     }
}
</script>