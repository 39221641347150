<template>
     <div>
     <v-dialog
        transition="dialog-top-transition"
        max-width="800"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >CHANGE STORE</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Choose One Store</v-toolbar>
            <v-card-text>
              <v-row class="py-3">
                   <v-col cols="12">
                        <h3>STORE: {{store.store_code}} - {{store.name}}, {{store.city}}</h3>
                        <small>selectedItem: {{selectedItem}}</small>
                   </v-col>
                   <v-col cols="12" v-if="stores">
                        <v-select
                        v-model="selectedItem"
                        :items="stores"
                        outlined
                        solo
                        item-value="store_uid"
                        item-text="name"
                        >

                        </v-select>
                   </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                outlined
                block
                @click="dialog.value = false"
              >{{$t('commons.close')}}</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
     </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     name:"ChangeStore",
     computed: {
     ...mapGetters({
          meta: 'managerStores/meta',
          stores: 'managerStores/stores',
          store: 'managerStores/store'
      }),
     },
     data: () => ({
          selectedItem: null,
     }),
     watch: {
          selectedItem (item) {
               this.select(item)
          }
     },
     beforeMount(){
          this.selectedItem = this.store.store_uid
     },
     methods: {
          select(item) {
               this.selectedItem = item
               this.$store.dispatch('managerStores/getStore', item)
          },
          page(page){
               this.meta.current_page = page
               this.$store.dispatch('managerStores/setMeta',this.meta)
               this.getStores()
          },
          async getStores(){
              this.loading = true;
               this.$store.dispatch('managerStores/setMeta',this.meta)
              this.$store.dispatch('managerStores/getStores')
              .then((response) => {
                   
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                 
               })
         }
     }
}
</script>