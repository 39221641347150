<template>
     <v-container>
          <v-row>
               <v-col cols="12">
                   <v-card>
                        <v-card-title primary-title>
                             <h1>{{$t('manager.stores.title')}}</h1>
                        </v-card-title>
                        <v-card-text>
                              <h3>{{$t('manager.stores.description')}}</h3>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12">
                    <v-card :loading="loading">
                        <v-card-text>
                         <paginated-table 
                           :headers="headers" 
                           :table="stores" 
                           id="store_uid" 
                           :meta="meta"
                           :loading="loading"
                           view
                           name="manager.stores.single"  
                           @change="getStores"
                         ></paginated-table>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          stores: 'managerStores/stores',
          meta: 'managerStores/meta'
      }),
     },
     components:{
     },
     data: () => ({
          loading: false,
           headers:[
               {text:'attributes.country.label', value: 'country', sortable: false},
               {text:'attributes.store_code.label', value: 'store_code', sortable: false},
               {text:'attributes.store_name.label', value: 'name', sortable: false},
               {text:'attributes.city.label', value: 'city', sortable: false},
          ],
          errors: null,
          message: null,
          filters: null,
     }),
     beforeMount() {
          if(this.stores.length < 1){          
               this.getStores()
          }
     },
     methods:{
         async getStores(){
              this.loading = true;
              this.$store.dispatch('managerStores/setMeta',this.meta)
              this.$store.dispatch('managerStores/getStores',this.filters)
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         }
     }
}
</script>