<template>
     <div>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title v-if="analytics">
                             <h3>{{ title }}</h3>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text  v-if="analytics">
                             <p>{{ description }}</p>
                             <datepicker 
                              label="FROM"
                              :max="range.end"
                              :date.sync="range.start"
                             ></datepicker>
                             <datepicker 
                              label="TO"
                              :min="range.start"
                              :max="this.$moment().format('YYYY-MM-DD')"
                              :date.sync="range.end"
                             ></datepicker>
                               <v-spacer></v-spacer>
                              <v-btn block @click="getAnalytics" outlined color="primary">get</v-btn>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title  v-if="analytics">
                            {{title}}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text v-if="chartData">
                             <p> {{ range.start | moment(" D MMMM YYYY") }} - {{ range.end | moment(" D MMMM YYYY") }}</p>
                             <apexchart :key="loading" width="100%" type="line" :options="chartOptions" :series="chartData"></apexchart>
                        </v-card-text>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
     </div>
</template>


<script>
import datepicker from '@/components/Commons/FormValues/datepicker.vue';
import {mapGetters} from "vuex";

export default {
     components: { 
          datepicker
     },
     computed: {
          ...mapGetters({
                analytics: 'managerAnalytics/single',
                store: 'managerStores/store',
          }),
     },
     beforeMount(){
          this.slug = this.$route.params.id;
          this.title = this.$route.params.id;
          this.description = this.$route.params.id;
     },
     mounted() {
          this.range.start = this.$moment().subtract(30,'Days').format('YYYY-MM-DD')
          this.range.end = this.$moment().format('YYYY-MM-DD')
         

          if(!this.analytics){
               this.getAnalytics()
          }else if(this.analytics[0].slug != this.$route.params.id){
               this.getAnalytics()
          }else{
               this.splitValues(this.analytics)
               this.loading = false;
          }
          
     },
     watch:{
          store(val){
               console.log('whatch store',val)
               if(!this.analytics){
                    this.getAnalytics()
               }
          }
     },
     data: () => ({
          loading: true,
          slug: null,
          title: null,
          description: null,
          range: {
               start: null,
               end: null,
          },
          labels: [],
          values: [],
          chartData: null,
          chartOptions: null,
     }),
     methods:{
          async getAnalytics(){
               var params = {
                    'table': this.$route.params.id,
                    'range': this.range
               }
               this.loading = true
               this.chartData = null
               this.chartOptions = null
               this.labels =  []
               this.values = []

               if(!this.store){
                    return 0;
               }else{
                    params.store_uid = this.store.store_uid
               }

              this.$store.dispatch('managerAnalytics/getSingleAnalytics',params)
              .then((response) => {
                  console.log(response)
                    this.splitValues(this.analytics)

              })
          },
           splitValues(data){

               data.forEach(element => {
                    this.labels.push(this.$moment(element.created_at).format('D MMMM YYYY'))
                    this.values.push(element.daily)

                    this.title = element.title;
                    this.description = element.description;

               });

               this.chartData = [{
                    name: this.title,
                    data: this.values
               }]

               this.chartOptions = {
                    chart: {
                         id: 'basic-line',
                         zoom: {
                              enabled: false
                         },
                    },
                    xaxis: {
                         categories: this.labels
                    },
                    colors: ['#546E7A'],
                    markers: {  
                         size: [4, 7],
                         colors: [ '#333' ],
                    },
                    stroke: {
                      curve: 'smooth',
                    },
                    dataLabels: {
                         enabled: false,
                    },
               }
               this.loading = false
                
          },
          
          
     }
}
</script>