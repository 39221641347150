var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[(_vm.store)?_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.store.name))])]),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.store.store_code)+" ")],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$t('commons.store'))+" ")]),(_vm.store)?_c('v-card-text',[_c('simple-table',{attrs:{"table":{
                             store_name:_vm.store.name,
                             state: _vm.store.state,
                             region: _vm.store.region,
                             timezone: _vm.store.timezone,
                             city:_vm.store.city,
                             address:_vm.store.address,
                             phone:_vm.store.phone_number,
                             email:_vm.store.email,
                        }}})],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('dialog-simple',{attrs:{"width":"800px","button":_vm.$t('commons.edit')+' '+_vm.$t('commons.store'),"title":_vm.$t('commons.store')}},[_vm._t("default",[_c('update-store',{attrs:{"store":_vm.store}})])],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$t('commons.managers'))+" ")]),(_vm.store)?_c('v-card-text',_vm._l((_vm.store.managers),function(manager){return _c('v-list-item',{key:manager.uid,attrs:{"to":{'name':'manager.users.single', 'params':{'id':manager.uid}}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.$t('roles.manager.icon'))}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(manager.fullname))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(manager.email))])],1)],1)}),1):_vm._e(),_c('v-divider'),_c('v-card-actions')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }