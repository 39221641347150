<template>
     <v-container>
          <v-row>
               <v-col cols="12">
                   <v-card>
                        <v-card-title primary-title>
                         <h1>{{$t('manager.dashboard.title')}}</h1>
                        </v-card-title>
                        <v-card-text>
                             <h3>{{$t('manager.dashboard.description')}}</h3>
                             <p class="mb-0" v-if="manager.profile.fullname">{{manager.profile.fullname}}</p>
                             <p class="mb-0" v-else>{{manager.email}}</p>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12" md="4" v-for="model in models" :key="model">
                   <v-card>
                        <v-card-title primary-title>
                             <v-avatar
                                             size="50"
                                             color="white"
                                             icon
                                             class="mx-3"
                                        >
                                             <v-icon large color="primary">{{$t('manager.'+model+'.icon')}}</v-icon>
                                   </v-avatar>
                             <div>
                                   <h2 class="mb-2">
                                  {{$t('manager.'+model+'.title')}}</h2>
                                  
                             </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                             <h3>{{$t('manager.'+model+'.description')}}</h3>
                        </v-card-text>
                        <v-card-actions class="text-right">
                             <v-spacer></v-spacer>
                           <v-btn :to="{name: 'manager.'+model+'.index'}" color="primary" >{{$t('manager.'+model+'.title')}}</v-btn>
                        </v-card-actions>
                   </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     components:{
     },
     computed: {
     ...mapGetters({
          manager: 'user/user',
          store: 'managerStores/store',
      }),
     },
     data: () =>({
          models: ['users','receipts','stores','analytics']
     }),
     methods:{
         
     },
}
</script>