<template>
     <v-container>
          <v-row>
               <v-col cols="12">
                   <v-card>
                        <v-card-title primary-title>
                             <h1>{{$t('manager.users.title')}}</h1>
                        </v-card-title>
                        <v-card-text>
                              <h3>{{$t('manager.users.description')}}</h3>
                        </v-card-text>
                   </v-card>
               </v-col>
               <v-col cols="12" md="8">
                    <v-card class="mb-3">
                        <v-card-title primary-title>
                             <div>
                                 {{$t('commons.search')}} {{$t('commons.user')}}
                             </div>
                        </v-card-title>
                        <v-card-text>
                            <search-user-form></search-user-form>
                        </v-card-text>
                    </v-card>
               </v-col>
               <v-col cols="12" md="4">
                    <v-card class="mb-3" v-if="store">
                         <v-overlay
                         absolute="absolute"
                         v-if="checkAviability()">

                              <v-card>
                              <v-card-text>
                                   <v-icon>mdi-lock</v-icon>
                                   BLOCKED
                              </v-card-text>
                              </v-card>

                         </v-overlay>
                        <v-card-title primary-title>
                             <div>
                                  {{$t('commons.create')}} {{$t('commons.user')}}
                             </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions >
                                      <dialog-fullscreen block :button="$t('commons.create')+' '+$t('commons.user')" :title="$t('commons.create')+' '+$t('commons.user')">
                                      <slot><create-user-form :store="store"></create-user-form></slot>
                                      </dialog-fullscreen>
                        </v-card-actions>
                    </v-card>
                    <v-card class="mb-3" v-if="store">
                         <v-overlay
                         absolute="absolute"
                         v-if="checkAviability()">

                              <v-card>
                              <v-card-text>
                                   <v-icon>mdi-lock</v-icon>
                                   BLOCKED
                              </v-card-text>
                              </v-card>

                         </v-overlay>
                        <v-card-title primary-title>
                             <div>
                                  {{$t('commons.search')}} {{$t('commons.user')}} {{ $t('commons.activate')}}
                             </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions >
                                      <dialog-fullscreen block :button="$t('commons.activate')" :title="$t('commons.search')+' '+$t('commons.user')+' '+ $t('commons.activate')">
                                      <slot>
                                          <activate-card-form :store="store"></activate-card-form>
                                      </slot>
                                      </dialog-fullscreen>
                        </v-card-actions>
                    </v-card>
                    <v-card class="my-3" v-if="store">
                         <v-overlay
                         absolute="absolute"
                         v-if="checkAviability()">

                              <v-card>
                              <v-card-text>
                                   <v-icon>mdi-lock</v-icon>
                                   BLOCKED
                              </v-card-text>
                              </v-card>

                         </v-overlay>
                        <v-card-title primary-title>
                             <div>
                                  {{$t('commons.restore')}}
                             </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-actions >
                            <dialog-simple block :button="$t('commons.restore')+' '+$t('commons.user')" :title="$t('commons.restore')+' '+$t('commons.user')">
                                   <slot>
                                        <restore-user-form :store_uid="store.store_uid"></restore-user-form>
                                   </slot>
                             </dialog-simple>
                        </v-card-actions>
                    </v-card>
               </v-col>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import SearchForm from '@/components/Manager/Users/SearchForm';
import CreateUserForm from '@/components/Manager/Users/CreateUserForm';
import RestoreUserForm from '@/components/Manager/Users/RestoreUserForm';
import ActivateCardForm from '@/components/Manager/Users/ActivateCardForm';
import DialogFullScreen from '@/components/Commons/DialogFullScreen';


export default {
     components:{
          'create-user-form': CreateUserForm,
          'restore-user-form': RestoreUserForm,
          'activate-card-form':ActivateCardForm,
          'dialog-fullscreen': DialogFullScreen,
          'search-user-form': SearchForm,
     },
     computed: {
     ...mapGetters({
          users: 'managerUsers/users',
          store: 'managerStores/store',
          meta: 'managerUsers/meta',
          usercountry: 'auth/country',
      }),
     },
     data: () => ({
          loading: false,
          errors: null,
          message: null,
          filters: null,
     }),
     beforeMount() {
          
     },
     methods:{
          checkAviability(){
               var  today = this.$moment();
               var lastday = this.$moment("2024-01-01");
               var  invalid_country = ['PRT','ES'];

               console.log("DATES!!!", [today , lastday])
               console.log("IS >=", today >= lastday)

               if(invalid_country.includes(this.usercountry) && today >= lastday){
                    return true
               }

               return false;
          }
     }
}
</script>