<template>
     <v-container class="my-5" >
          <v-row>
               <v-col cols="12">
                   <v-card :loading="loading">
                        <v-card-title primary-title>
                            <h1>{{$t('admin.analytics.title')}} </h1>
                        </v-card-title>
                        <v-card-text v-if="store">
                             {{$t('admin.analytics.description')}}: {{store.name}}
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                             <v-row>
                                  <v-col cols="10" class="mx-auto" >
                                       <datepicker 
                                        v-if="today"
                                             outlined
                                             label="Select a date"
                                             class="mt-5"
                                             min="2021-05-01"
                                             :max="this.$moment().format('YYYY-MM-DD')"
                                             :date.sync="today"
                                        ></datepicker>
                                  </v-col>
                                  <!-- <v-col  cols="12" md="4">
                                        <v-btn block @click="rigenerateAnalytics" color="primary">rigenerate for today</v-btn>
                                   </v-col> -->
                              </v-row>
                        </v-card-actions>
                   </v-card>
               </v-col>
               <v-col cols="12">
                    <v-card>
                         
                    </v-card>
               </v-col>
               <template v-if="analytics" >
               <v-col cols="12" md="6" v-for="(analysis,index) in analytics.data" :key="analysis.table+index" >
                    <v-card :loading="loading">
                         <v-card-title primary-title>
                              <h3>{{ analysis.title }}</h3>
                         </v-card-title>
                         <v-card-text>
                              <p>{{ analysis.description }}</p>
                         </v-card-text>
                         <v-divider></v-divider>
                         <v-card-text>
                               <template v-if="!analysis.measure_unit">
                               <simple-table :table="{
                                   daily: analysis.daily,
                                   yesterday: analysis.yesterday,
                                   weekly: analysis.weekly,
                                   last_week: analysis.last_week,
                                   monthly: analysis.monthly,
                                   last_month: analysis.last_month,
                                   annual: analysis.annual,
                                   total: analysis.total,
                                   updated_at: analysis.updated_at,
                               }" ></simple-table> 
                               </template>
                               <template v-if="analysis.measure_unit">
                               <simple-table :table="{
                                   daily: analysis.daily+' '+analysis.measure_unit,
                                   yesterday: analysis.yesterday+' '+analysis.measure_unit,
                                   weekly: analysis.weekly+' '+analysis.measure_unit,
                                   last_week: analysis.last_week+' '+analysis.measure_unit,
                                   monthly: analysis.monthly+' '+analysis.measure_unit,
                                   last_month: analysis.last_month+' '+analysis.measure_unit,
                                   annual: analysis.annual+' '+analysis.measure_unit,
                                   total: analysis.total+' '+analysis.measure_unit,
                                   updated_at: analysis.updated_at,
                               }" ></simple-table> 
                               </template>
                         </v-card-text>
                         <v-card-actions>
                              <v-btn outlined block :to="{'name':'manager.analytics.single',params:{'id':analysis.slug}}" color="primary">
                                   {{$t('commons.view')}} {{$t('commons.chart')}}
                              </v-btn>
                         </v-card-actions>
                    </v-card>
               </v-col>
               </template>
          </v-row>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import datepicker from '@/components/Commons/FormValues/datepicker.vue';

export default {
     computed: {
     ...mapGetters({
          analytics: 'managerAnalytics/analytics',
          store: 'managerStores/store',
      }),
     },
     components:{
          datepicker
     },
     data: () => ({
          loading: true,
          errors: {},
          message: null,
          today: null
     }),
     watch:{
          today(val){
               console.log(val)
               this.getAnalytics()
          },
          store(val){
               console.log(val)
               this.getAnalytics()
          }
     },
     mounted() {
          this.today = this.$moment().format('YYYY-MM-DD')       
          if(this.store){
               this.getAnalytics()
          }else{
               this.loading = true;
          }
     },
     methods:{
         async getAnalytics(){
              this.loading = true;

               if(!this.store){
                    return 0;
               }

              this.$store.dispatch('managerAnalytics/getAnalytics',{today:this.today,store_uid:this.store.store_uid})
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         },
         async rigenerateAnalytics(){
              this.loading = true;
              this.today = this.$moment().format('YYYY-MM-DD');
              this.$store.dispatch('managerAnalytics/rigenerateAnalytics',{today:this.today,store_uid:this.store.store_uid})
              .then((response) => {
                    this.loading = false
                    this.message = null
                    this.errors = null
                    console.log(response)
              })
              .catch((err) => {
                    console.error('ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               })
         }
     }
}
</script>